import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import getImages from "../utils/getImages"

const NotFoundPage = ({ data, location }) => {
  const { t } = useTranslation()
  const siteTitle = data.site.siteMetadata.title

  const images = getImages(
    getImage(data.mobileBackground),
    getImage(data.tabletBackground),
    getImage(data.desktopBackground)
  )

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <div className="page">
        <GatsbyImage
          className="editorial--background"
          image={images}
          alt={"Background image"}
        />

        <h1 className="error-page--title">{t("error.title")}</h1>
        <p className="error-page--label">{t("error.text1")}</p>
        <p className="error-page--label bold">
          <b>{t("error.text2")}</b>
        </p>
        <p className="error-page--label">{t("error.text3")}</p>
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="error-page--label">{t("error.cta")}</span>
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    mobileBackground: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "contact-background" }
      extension: { eq: "jpg" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    tabletBackground: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "contact-background-tablet" }
      extension: { eq: "jpg" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    desktopBackground: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "contact-background-desktop" }
      extension: { eq: "jpg" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
